<template>
  <BaseQRScanner></BaseQRScanner>
</template>

<script>
import BaseQRScanner from "./components/BaseQRScanner.vue";

export default {
  name: "App",
  components: {
    BaseQRScanner,
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}
</style>
