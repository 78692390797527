<template>
  <Transition name="fade">
    <div class="fixed top-0 left-0 w-full h-full base-qr-scanner font-sirius">
      <video
        ref="scanner"
        class="fixed top-0 w-full h-full object-cover"
        disablePictureInPicture
      ></video>
      <div class="fixed base-qr-scanner__wrapper">
        <div class="text-center mb-4">Наведите камеру на QR-код</div>
        <div class="base-qr-scanner__square"></div>
      </div>
      <div
        class="fixed bottom-8 w-full flex justify-center"
        @click="closeScanner"
      ></div>
    </div>
  </Transition>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  name: "BaseQRScanner",
  emits: ["scanned", "restricted"],
  setup(_, { emit }) {
    const scanner = ref(null);
    let qrScannerInstance = null;
    const qrScannerReady = ref(false);

    const handleWebScanner = () => {
      import("qr-scanner").then((module) => {
        const QrScanner = module.default;
        qrScannerInstance = new QrScanner(
          scanner?.value,
          ({ data }) => {
            if (data.length) {
              qrScannerInstance.destroy();
              emit("scanned", data);
              const urlPath = new URL(data);

              if (urlPath.hostname === "rahmetapp.kz") {
                urlPath.searchParams.set("fromWebQRScan", "true");
                location.href = urlPath.href;
              } else {
                location.href = urlPath.href;
              }
            }
          },
          {
            returnDetailedScanResult: true,
          }
        );

        qrScannerInstance
          .start()
          .then(() => (qrScannerReady.value = true))
          .catch(() => {
            closeScanner();
            handleWebScanner();
          });
      });
    };

    const closeScanner = () => {
      emit("restricted");
      qrScannerInstance.destroy();
    };

    onMounted(() => {
      handleWebScanner();
    });

    return {
      scanner,
      qrScannerReady,
      closeScanner,
    };
  },
};
</script>

<style lang="scss" scoped>
.base-qr-scanner {
  z-index: 123;
  background: #1f1f1f;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.base-qr-scanner__wrapper {
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  position: fixed;
  color: rgb(255 255 255 / 72%);
  & > div {
    text-align: center;
    margin-bottom: 4px;
  }
}

.base-qr-scanner__square {
  height: 285px;
  width: 285px;
  margin: 0 auto;
  box-shadow: 0 0 0 500px rgb(0 0 0 / 80%);
  border-radius: 2.22rem;
}
</style>
